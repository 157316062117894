.label::before {
  position: absolute;
  color: white;
  height: 1px;
  background-color: white;
  width: calc(100% + 100px);
  transform: translateX(-100px);
  top: 0;
  left: 0;
  content: "";
}

.label {
  pointer-events: none;
  user-select: none;
  font-size: 20px;
  font-weight: bold;
  color: white;
  transform: translateX(100px);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.loading {
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
